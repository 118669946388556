@import './variables.scss';
@import './mixins/font-mixins.scss';
@import './mixins/media-mixins.scss';
@import './mixins/common-mixins.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  color: var(--base-black);
  // background-color: $color-background !important;
  position: fixed;
}

@media (max-width: $screen-md) {
  tui-root,
  .main {
    height: 100%;
  }
}

a {
  cursor: pointer;
}

.loading,
.loading .p-inputnumber-input,
.loading .p-dropdown,
.loading .p-multiselect {
  cursor: default;
  pointer-events: none;
  background-color: var(--base-loading);
  border-radius: 6px;
  opacity: 0.6;
}

textarea {
  min-height: 61px;
  max-height: 300px;
  overflow: auto !important;
}

.width {
  &_auto {
    flex: auto !important;
  }
}

.absolute {
  &_top-right {
    position: absolute;
    top: 2px;
    right: 16px;

    display: flex;
    gap: 5px;
    flex-direction: column;
  }
}

// -------- Customization lucide --------

lucide-icon {
  display: flex;
  align-items: center;

  svg {
    stroke-width: 1.5;
  }
}

// -------- Customization PrimeNG --------

.p-button {
  &:focus {
    box-shadow: none;
  }
}

.p-button-label {
  font-weight: 400;
}

.p-breadcrumb {
  border: none;
  background-color: initial;
}

.p-toolbar {
  background-color: var(--base-white);
}

.p-tooltip {
  $default-color: var(--logo-blue-900);

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: $default-color;
    }
  }
  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: $default-color;
    }
  }
  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: $default-color;
    }
  }
  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-left-color: $default-color;
    }
  }
  .p-tooltip-text {
    background-color: $default-color;
  }
}

p-inputswitch {
  display: flex;
}

.p-toast-message-text {
  overflow: hidden;
}

// CUSTOM BUTTONS - START

// p-button-red - START
$color-red: var(--blue-red-400);
.p-button.p-button-red,
.p-button.p-button-red:enabled:hover,
.p-button.p-button-red:enabled:active {
  color: $color-red;
}

.p-button.p-button-red:enabled:hover {
  background: rgba($color-red, 0.04);
}

.p-button.p-button-red:enabled:active {
  background: rgba($color-red, 0.16);
}

.p-button.p-button-red:focus {
  box-shadow:
    0 0 0 2px var(--primary-color-text),
    0 0 0 4px rgba($color-red, 0.7),
    0 1px 2px 0 rgb(0 0 0 / 0%);
}
// p-button-red - END
