@import '../variables.scss';

@mixin media-down($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}

@mixin media-up($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}

@mixin media-between($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

/*
  Миксина мобильной верстки (размер до 767px)
 */
@mixin mobile() {
  @media screen and (max-width: $screen-sm-max) {
    @content;
  }
}

/*
  Миксина десктопной верстки (размер > 768px)
 */
@mixin desktop() {
  @media screen and (min-width: $screen-md) {
    @content;
  }
}

/*
  Миксина десктопной верстки (размер между 1280px и 1920px)
 */
@mixin desktop-xl() {
  @media screen and (min-width: $screen-xl) and (max-width: $screen-xxl) {
    @content;
  }
}
