// ------------------------ COLORS ------------------------------
:root {
  // ---------------------- Наши переменные ----------------------
  --base-white: #ffffff;
  --base-black: #212529;
  --base-grey: #8d91a0;
  --base-loading: #e5e7eb;

  --blue-gray-900: #0f172a;
  --blue-gray-800: #1e293b;
  --blue-gray-700: #334155;
  --blue-gray-600: #475569;
  --blue-gray-500: #64748b;
  --blue-gray-400: #94a3b8;
  --blue-gray-300: #cbd5e1;
  --blue-gray-200: #e2e8f0;
  --blue-gray-100: #f1f5f9;
  --blue-gray-50: #f8fafc;

  --blue-red-900: #7f1d1d;
  --blue-red-800: #991b1b;
  --blue-red-700: #b91c1c;
  --blue-red-600: #dc2626;
  --blue-red-500: #ef4444;
  --blue-red-400: #f87171;
  --blue-red-300: #fca5a5;
  --blue-red-200: #fecaca;
  --blue-red-100: #fee2e2;
  --blue-red-50: #fef2f2;

  --logo-blue-900: #317db9;
  --logo-blue-800: #6aade2;
  --logo-blue-700: #aad9ff;
  --logo-blue-600: #d5edff;
  --logo-blue-400: #ebf6ff;
}

// ---------------------- BREAKPOINTS ---------------------------

// keep it equal with enum eBreakpoints
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;
$screen-xxl: 1920px;

$screen-xs-max: 575px;
$screen-sm-max: 767px;
$screen-md-max: 1023px;
$screen-lg-max: 1279px;
$screen-xl-max: 1919px;

// ---------------------- OTHER ---------------------------
:root {
  --header-height: 58px;
}
